// components/TopicClusters.jsx
import React from 'react';
import { Card, CardContent } from './card';
import { ChevronRight } from 'lucide-react';
import { cn } from '../utils';

// Get a random background gradient based on the cluster ID
const getRandomGradient = (id) => {
  const gradients = [
    'bg-gradient-to-br from-pink-500 to-orange-400',
    'bg-gradient-to-br from-green-400 to-blue-500',
    'bg-gradient-to-br from-purple-500 to-indigo-500',
    'bg-gradient-to-br from-yellow-400 to-orange-500',
    'bg-gradient-to-br from-blue-400 to-emerald-400',
    'bg-gradient-to-br from-red-500 to-pink-500',
    'bg-gradient-to-br from-teal-400 to-blue-500',
    'bg-gradient-to-br from-amber-400 to-rose-500',
    'bg-gradient-to-br from-indigo-500 to-purple-500',
    'bg-gradient-to-br from-cyan-400 to-blue-500',
  ];
  
  // Use the numeric part of the ID to select a gradient
  const numericId = parseInt(id, 10) || 0;
  return gradients[numericId % gradients.length];
};

const TopicCard = ({ cluster, id, isSelected, onClick }) => {
  const bgGradient = getRandomGradient(id);
  
  return (
    <div
      className={cn(
        "border rounded-lg overflow-hidden cursor-pointer transition-all duration-200 shadow-sm hover:shadow-md",
        isSelected 
          ? "border-twitter-blue ring-2 ring-twitter-blue/20" 
          : "border-gray-200 dark:border-gray-700"
      )}
      onClick={onClick}
    >
      {/* Colorful header */}
      <div className={cn("p-3", bgGradient)}>
        <h4 className="font-bold text-white text-lg break-words">
          {cluster.topic_label}
        </h4>
      </div>
      
      <div className="p-4">
        {/* Show original label if available */}
        {cluster.original_label && (
          <div className="text-xs text-gray-500 dark:text-gray-400 mb-2 italic">
            Original: {cluster.original_label}
          </div>
        )}
        
        <ul className="list-disc list-inside mb-3 pl-1">
          {cluster.topic_terms.slice(0, 5).map((term, index) => (
            <li key={index} className="text-gray-600 dark:text-gray-400">{term}</li>
          ))}
        </ul>
        
        <div className="flex items-center justify-between text-sm pt-2 border-t border-gray-100 dark:border-gray-700">
          <span className="text-gray-500 dark:text-gray-400 font-medium">
            {cluster.size} tweets
          </span>
          <span className="text-twitter-blue flex items-center group-hover:translate-x-0.5 transition-transform">
            View tweets <ChevronRight className="w-4 h-4 ml-1" />
          </span>
        </div>
      </div>
    </div>
  );
};

const TopicClusters = ({ clusters, selectedTopic, onTopicSelect }) => {
  const validClusters = Object.entries(clusters)
    .filter(([id, cluster]) => 
      id !== '-1' && 
      cluster.topic_label && 
      !cluster.topic_label.includes('unknown')
    );

  return (
    <Card className="mb-6 insight-topics">
      <CardContent>
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">The Unhinged Twitter Universe</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {validClusters.map(([id, cluster]) => (
            <TopicCard
              key={id}
              id={id}
              cluster={cluster}
              isSelected={selectedTopic === id}
              onClick={() => onTopicSelect(id)}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TopicClusters;