import { useLocation, useNavigate } from 'react-router-dom';  
import React, { useState, useMemo, useEffect } from 'react';
import { Filter, Shuffle, ArrowUpDown } from 'lucide-react';
import RedditCard from './ui/RedditCard';
import TweetCard from './ui/TweetCard';
import FilterPopover from './ui/FilterPopover';
import SortPopover from './ui/SortPopover';
import { useDevMode } from '../context/DevModeContext';
import './TwitterSearch.css';
import { debounce } from 'lodash';


const TWEETS_PER_PAGE = 10;

const TwitterSearch = ({ allTweets, darkMode, onTweetUpdate }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDevMode } = useDevMode();
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem('twitterSearchFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      likes: true,
      bookmarks: true,
      user_tweets: false,
      reddit: false,
      withImages: true,
      withVideos: true,
      textOnly: true,
      over18: false,
      onlyOver18: false,
    };
  });


  const [currentSort, setCurrentSort] = useState(() => {
    const savedSort = localStorage.getItem('twitterSearchSort');
    return savedSort || 'newest';
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('twitterSearchFilters', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    localStorage.setItem('twitterSearchSort', currentSort);
  }, [currentSort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q');
    if (searchQuery) {
      setSearchTerm(decodeURIComponent(searchQuery));
    }
  }, [location.search]);

  const getSortedTweets = (tweets, sortType) => {
    const tweetsWithEngagement = tweets.map(tweet => ({
      ...tweet,
      engagement: tweet.source === 'reddit'
        ? (tweet.ups + tweet.num_comments)
        : ((tweet.retweet_count || 0) + (tweet.reply_count || 0))
    }));

    switch (sortType) {
      case 'newest':
        return [...tweets].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      case 'oldest':
        return [...tweets].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'mostEngaged':
        return tweetsWithEngagement.sort((a, b) => b.engagement - a.engagement);
      case 'leastEngaged':
        return tweetsWithEngagement.sort((a, b) => a.engagement - b.engagement);
      default:
        return tweets;
    }
  };

  const filteredTweets = useMemo(() => {
    return allTweets.filter((tweet) => {
      const parseSearch = (searchTerm) => {
        const terms = [];
        const operators = {
          from: null,
          subreddit: null,
          is: null,
          not: null
        };

        const regex = /\(([a-zA-Z]+:[^\)]+)\)|("[^"]+"|[^\s]+)/g;
        let match;

        while ((match = regex.exec(searchTerm)) !== null) {
          const [fullMatch, operatorMatch, term] = match;

          if (operatorMatch) {
            const [operator, value] = operatorMatch.split(':');
            operators[operator] = value;
          } else if (term) {
            const cleanTerm = term.replace(/"/g, '');
            const termParts = cleanTerm.split('|');
            terms.push(...termParts);
          }
        }

        return { terms, operators };
      };

      const { terms, operators } = parseSearch(searchTerm);
      const searchTerms = terms.map(term => term.toLowerCase());

      const textMatches = searchTerms.length === 0 || searchTerms.some(term =>
        (tweet.text || tweet.title || '').toLowerCase().includes(term.toLowerCase())
      );

      const fromMatch = !operators.from ||
        (tweet.user?.screen_name || tweet.user?.handle || '').toLowerCase() === operators.from.toLowerCase() ||
        (tweet.user?.name || '').toLowerCase() === operators.from.toLowerCase();

      const subredditMatch = !operators.subreddit ||
        (tweet.source === 'reddit' && tweet.subreddit?.display_name?.toLowerCase() === operators.subreddit.toLowerCase());

      const typeMatch = !operators.is || (
        operators.is === 'reddit' ? tweet.source === 'reddit' :
          operators.is === 'tweet' ? ['like', 'bookmark', 'user_tweet'].includes(tweet.source) :
            operators.is === 'like' ? tweet.source === 'like' :
              operators.is === 'bookmark' ? tweet.source === 'bookmark' :
                true
      );

      const matchesSearch = textMatches && fromMatch && subredditMatch && typeMatch;

      const matchesSource =
        (filters.likes && tweet.source === 'like') ||
        (filters.bookmarks && tweet.source === 'bookmark') ||
        (filters.user_tweets && tweet.source === 'user_tweet') ||
        (filters.reddit && tweet.source === 'reddit');

      const hasMedia = tweet.medias && tweet.medias.length > 0;
      const hasImages = hasMedia && tweet.medias.some(media => media.type === 'photo');
      const hasVideos = hasMedia && tweet.medias.some(media => media.type === 'video');

      const matchesMedia =
        (filters.withImages && hasImages) ||
        (filters.withVideos && hasVideos) ||
        (filters.textOnly && !hasMedia);

      const isOver18 = tweet.over_18 ?? false;

      const matchesAdultContent =
        (filters.onlyOver18 && isOver18) ||
        (!filters.onlyOver18 && (filters.over18 || !isOver18));

      const notMatch = operators.not === null ||
        !(tweet.text || tweet.title || '').toLowerCase().includes(operators.not.toLowerCase());

      return matchesSearch && notMatch && matchesSource && (tweet.source === 'reddit' || matchesMedia) && matchesAdultContent;
    });
  }, [allTweets, searchTerm, filters]);

  useEffect(() => {
    const sortedTweets = getSortedTweets(filteredTweets, currentSort);
    setDisplayedTweets(sortedTweets.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  }, [filteredTweets, currentSort]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const sortedTweets = getSortedTweets(filteredTweets, currentSort);
    const nextTweets = sortedTweets.slice(0, nextPage * TWEETS_PER_PAGE);
    setDisplayedTweets(nextTweets);
    setCurrentPage(nextPage);
  };

  const handleShuffle = () => {
    const shuffled = [...filteredTweets].sort(() => 0.5 - Math.random());
    setDisplayedTweets(shuffled.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  };

  const handleTweetUpdate = async (updatedTweet) => {
    if (!isDevMode) return;

    try {
      const fileMap = {
        'like': '/exports/likes/merged_tweets.json',
        'bookmark': '/exports/bookmarks/bookmarks.json',
        'user_tweet': '/exports/user_tweets/20241015_user_tweets.json'
      };

      const filePath = fileMap[updatedTweet.source];
      if (!filePath) {
        throw new Error(`Unknown tweet source: ${updatedTweet.source}`);
      }

      console.log('Sending update to dev server:', {
        tweet: updatedTweet,
        filePath: filePath
      });

      // Send update to dev server
      const response = await fetch('http://localhost:3001/api/update-tweet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tweet: updatedTweet,
          filePath: filePath
        }),
      });

      console.log('Server response status:', response.status);
      const responseText = await response.text();
      console.log('Server response text:', responseText);

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${responseText}`);
      }

      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (e) {
        throw new Error(`Invalid JSON response: ${responseText}`);
      }

      // Just update the specific tweet in displayedTweets
      setDisplayedTweets(prevTweets =>
        prevTweets.map(tweet =>
          tweet.id === updatedTweet.id ? updatedTweet : tweet
        )
      );

      return true; // Signal success to the TweetCard

    } catch (error) {
      console.error('Failed to update tweet:', error);
      alert(`Failed to save tweet update: ${error.message}`);
      throw error; // Propagate error to TweetCard
    }
  };

  const renderTweet = (tweet) => {
    if (!tweet) return null;

    if (tweet.source === 'reddit') {
      return <RedditCard key={tweet.id} post={tweet} />;
    }

    return (
      <TweetCard
        key={tweet.id}
        tweet={tweet}
        onUpdateTweet={handleTweetUpdate}
      />
    );
  };

  const updateSearchURL = useMemo(
    () =>
      debounce((search) => {
        const params = new URLSearchParams(location.search);
        if (search) {
          params.set('q', search);
        } else {
          params.delete('q');
        }
        navigate(`/?${params.toString()}`, { replace: true });
      }, 300),
    [navigate, location.search]
  );

  // Add the new search handler
  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearchTerm(newSearch);
    updateSearchURL(newSearch);
  };

  // Clear debounce on unmount
  useEffect(() => {
    return () => {
      updateSearchURL.cancel();
    };
  }, [updateSearchURL]);

  return (
    <div className={`twitter-search mt-8 ${darkMode ? 'dark-mode' : ''}`}>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search (try: (from:user) (subreddit:name) (is:type))"
          value={searchTerm}
          onChange={handleSearchChange}  // Updated to use new handler
          className="search-input"
          title="Search operators (must be in parentheses):
• (from:username) - Find content from specific user
• (subreddit:name) - Find posts from specific subreddit
• (is:type) - Filter by type (reddit/tweet/like/bookmark)
• Use quotes for exact phrases"
        />
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="filter-button"
          aria-label="Open filters"
        >
          <Filter size={20} />
        </button>
        {isFilterOpen && (
          <FilterPopover
            filters={filters}
            setFilters={setFilters}
            onClose={() => setIsFilterOpen(false)}
          />
        )}
      </div>
      <p className="mb-4 subtitle">
        Explore & rediscover my liked and bookmarked tweets as well as my Reddit saves!
      </p>
      <p className="mb-4 total-tweets">
        Total saved items: <span className="font-italic">{allTweets.length}</span> (
        Likes: <span className="font-italic">{allTweets.filter(t => t.source === 'like').length}</span>,
        Bookmarks: <span className="font-italic">{allTweets.filter(t => t.source === 'bookmark').length}</span>,
        User Tweets: <span className="font-italic">{allTweets.filter(t => t.source === 'user_tweet').length}</span>,
        Reddit: <span className="font-italic">{allTweets.filter(t => t.source === 'reddit').length}</span>)
      </p>
      {searchTerm && (
        <p className="mb-4 search-results">
          Found {filteredTweets.length} results for "{searchTerm}"
        </p>
      )}
      <div className="mb-4 flex space-x-2">
        <button onClick={handleShuffle} className="shuffle-button">
          <Shuffle className="inline-block mr-2" size={20} />
          Shuffle Saves
        </button>
        <div className="relative">
          <button
            onClick={() => setIsSortOpen(!isSortOpen)}
            className="shuffle-button"
            aria-label="Sort saves"
          >
            <ArrowUpDown className="inline-block mr-2" size={20} />
            Sort Saves
          </button>
          {isSortOpen && (
            <SortPopover
              currentSort={currentSort}
              setSort={setCurrentSort}
              onClose={() => setIsSortOpen(false)}
            />
          )}
        </div>
      </div>
      <div>{displayedTweets.map(renderTweet)}</div>
      {displayedTweets.length < filteredTweets.length && (
        <button
          onClick={handleLoadMore}
          className="load-more-button"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default TwitterSearch;