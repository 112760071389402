import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { COLORS, createMuseumArchitecture } from './MuseumUtils';
import { MuseumLayoutManager } from './topologyUtils';
import './GenerativeMuseum.css';

const GenerativeMuseum = ({ tweets }) => {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const controlsRef = useRef(null);
  const animationFrameRef = useRef(null);
  const centralpieceRef = useRef(null);
  const skyboxRef = useRef(null);
  const environmentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [logMessages, setLogMessages] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [clusterSelectionOpen, setClusterSelectionOpen] = useState(true);
  const [availableClusters, setAvailableClusters] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [filteredTweets, setFilteredTweets] = useState(tweets);
  const [cameraState, setCameraState] = useState({
    position: { x: 0, y: 2, z: 20 }, // Lower height so camera is not pointing down
    target: { x: 0, y: 2, z: 0 }, // Target at same height as camera to look straight ahead
    movementSpeed: 0.5,
    keysPressed: {}
  });
  
  const cameraStateRef = useRef(cameraState);
  
  // Keep the ref updated with the latest state
  useEffect(() => {
    cameraStateRef.current = cameraState;
  }, [cameraState]);

  // Logging function
  const log = (message) => {
    console.log(`Museum: ${message}`);
    setLogMessages(prev => [...prev, { id: Date.now(), message }]);
  };
  
  // Create a beautiful sky background inspired by the WebGPU cloud example
  const createSkyBackground = (scene) => {
    log('Creating beautiful sky with clouds inspired by WebGPU example');
    
    // Create a Sky mesh from three's sky implementation
    const sky = new Sky();
    sky.scale.setScalar(450000);
    scene.add(sky);
    
    // Get sky uniform references
    const skyUniforms = sky.material.uniforms;
    
    // Set sky parameters for a beautiful look
    skyUniforms['turbidity'].value = 10;
    skyUniforms['rayleigh'].value = 2;
    skyUniforms['mieCoefficient'].value = 0.005;
    skyUniforms['mieDirectionalG'].value = 0.8;
    
    // Define sun position parameters for dramatic lighting angle
    const sunPosition = new THREE.Vector3();
    const theta = Math.PI * (0.25); // Lower sun altitude for longer shadows
    const phi = 2 * Math.PI * (0.35); // Sun azimuth for angled light
    
    // Calculate sun position from angles
    sunPosition.x = Math.cos(phi);
    sunPosition.y = Math.sin(phi) * Math.sin(theta);
    sunPosition.z = Math.sin(phi) * Math.cos(theta);
    
    skyUniforms['sunPosition'].value.copy(sunPosition);
    
    // Create a sun directional light with moderate intensity for softer shadows
    const sunLight = new THREE.DirectionalLight(0xffffeF, 1.8);
    sunLight.position.set(sunPosition.x * 100, sunPosition.y * 100, sunPosition.z * 100);
    sunLight.castShadow = true;
    scene.add(sunLight);
    
    // Improve shadow quality and make shadows sharper
    sunLight.shadow.mapSize.width = 4096; // Higher resolution shadows
    sunLight.shadow.mapSize.height = 4096;
    sunLight.shadow.camera.near = 0.5;
    sunLight.shadow.camera.far = 1000;
    sunLight.shadow.bias = -0.0005; // Slightly increased to reduce shadow acne while keeping shadows soft
    
    // Set up shadow camera to cover more area
    const shadowSize = 200;
    sunLight.shadow.camera.left = -shadowSize;
    sunLight.shadow.camera.right = shadowSize;
    sunLight.shadow.camera.top = shadowSize;
    sunLight.shadow.camera.bottom = -shadowSize;
    
    // Add blur for softer shadows
    sunLight.shadow.radius = 3; // Higher values = softer, more blurred shadows
    
    // Debug helper to visualize the shadow camera frustum
    // const helper = new THREE.CameraHelper(sunLight.shadow.camera);
    // scene.add(helper);
    
    // Increase ambient light intensity for softer shadows and less contrast
    const ambientLight = new THREE.AmbientLight(0xC9E6FF, 0.65);
    scene.add(ambientLight);
    
    // Add some clouds using geometry to simulate volumetric effect
    // Create 3D cloud particles
    const createClouds = () => {
      // Group to hold all clouds
      const cloudsGroup = new THREE.Group();
      
      // Create cloud material with soft gradients
      const cloudMaterial = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        emissive: 0xaaaaaa,
        emissiveIntensity: 0.2,
        transparent: true,
        opacity: 0.85,
        roughness: 1.0,
        metalness: 0.0
      });
      
      // Create 20 cloud clusters
      for (let i = 0; i < 20; i++) {
        const cloudCluster = new THREE.Group();
        
        // Random position high in the sky
        const radius = 250 + Math.random() * 200;
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.random() * Math.PI * 0.3; // Keep clouds in upper hemisphere
        
        // Convert to Cartesian coordinates
        cloudCluster.position.x = radius * Math.sin(phi) * Math.cos(theta);
        cloudCluster.position.y = 150 + Math.random() * 100; // Height above ground
        cloudCluster.position.z = radius * Math.sin(phi) * Math.sin(theta);
        
        // Randomly rotate the cloud
        cloudCluster.rotation.set(
          Math.random() * Math.PI,
          Math.random() * Math.PI,
          Math.random() * Math.PI
        );
        
        // Create 5-15 cloud puffs per cluster
        const puffCount = 5 + Math.floor(Math.random() * 10);
        
        for (let j = 0; j < puffCount; j++) {
          // Create cloud puff with stretched sphere
          const puffSize = 10 + Math.random() * 25;
          const puffGeometry = new THREE.SphereGeometry(puffSize, 8, 8);
          
          // Create mesh and position randomly within cluster
          const puff = new THREE.Mesh(puffGeometry, cloudMaterial);
          puff.castShadow = true; // Make each cloud puff cast a shadow
          
          // Position puff within cluster
          puff.position.set(
            (Math.random() - 0.5) * 50,
            (Math.random() - 0.5) * 15,
            (Math.random() - 0.5) * 50
          );
          
          // Scale puff to be wider than tall for cloud-like appearance
          const scaleY = 0.3 + Math.random() * 0.4;
          puff.scale.set(1, scaleY, 1);
          
          // Add puff to cluster
          cloudCluster.add(puff);
        }
        
        // Add animation data to cluster
        cloudCluster.userData.animation = {
          rotationSpeed: { 
            x: 0, 
            y: (0.0001 + Math.random() * 0.0002) * (Math.random() > 0.5 ? 1 : -1), 
            z: 0 
          },
          floatSpeed: 0.1 + Math.random() * 0.2,
          floatHeight: 2 + Math.random() * 5,
          startTime: Math.random() * 100
        };
        
        // Store original Y position for floating animation
        cloudCluster.userData.originalY = cloudCluster.position.y;
        
        // Add cluster to clouds group
        cloudsGroup.add(cloudCluster);
      }
      
      return cloudsGroup;
    };
    
    // Create and add clouds
    const clouds = createClouds();
    scene.add(clouds);
    
    // Create a more detailed ground plane with a grid pattern to enhance shadow visibility
    const groundSize = 10000;
    const groundGeometry = new THREE.PlaneGeometry(groundSize, groundSize, 32, 32);
    
    // Create a material that will emphasize shadows
    const groundMaterial = new THREE.MeshStandardMaterial({
      color: 0x2a3a48,
      roughness: 0.88,
      metalness: 0.05,
      side: THREE.FrontSide
    });
    
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2; // Rotate to be horizontal
    ground.position.y = -50; // Below the museum
    ground.receiveShadow = true;
    
    // Add a grid pattern to help visualize shadows
    const gridHelper = new THREE.GridHelper(500, 50, 0x555555, 0x222222);
    gridHelper.position.y = -49.9; // Slightly above ground to avoid z-fighting
    gridHelper.material.transparent = true;
    gridHelper.material.opacity = 0.3; // Semi-transparent grid
    scene.add(gridHelper);
    
    scene.add(ground);
    
    // Add moderate atmospheric fog for depth and softer shadows
    scene.fog = new THREE.FogExp2(0xaac5ea, 0.0012);
    
    log('Enhanced sky with clouds created');
    return sky;
  };
  
  // Load topic clusters data from the analysis summary JSON
  const fetchTopicData = useCallback(async () => {
    try {
      log('Fetching topic clusters data...');
      
      // Try to fetch the analysis_summary.json file
      const response = await fetch('/exports/insights/analysis_summary.json');
      
      if (!response.ok) {
        throw new Error('Failed to fetch analysis summary');
      }
      
      const data = await response.json();
      
      // Check if semantic_clusters data exists in the summary
      if (data && data.semantic_clusters && data.semantic_clusters.clusters) {
        // Convert from object to array format
        const clusters = Object.entries(data.semantic_clusters.clusters)
          .filter(([id]) => id !== '-1') // Skip any "unknown" cluster with ID -1
          .map(([id, cluster]) => ({
            topic_id: id,
            topic_label: cluster.topic_label || `Topic ${Number(id) + 1}`,
            topic_terms: cluster.topic_terms || [],
            // Convert terms to keywords for compatibility
            keywords: cluster.topic_terms || [],
            size: cluster.size || 0,
            // Include any other relevant data
            original_label: cluster.original_label
          }));
        
        log(`Found ${clusters.length} topic clusters from analysis`);
        setAvailableClusters(clusters);
      } else {
        // If no semantic clusters found, use default categories
        throw new Error('No semantic clusters found in analysis');
      }
    } catch (error) {
      console.error('Error loading topic data:', error);
      log('Using default topic categories');
      
      // Create default categories based on common tweet topics
      const defaultTopics = [
        { 
          topic_id: "0", 
          topic_label: "Technology", 
          keywords: ['software', 'code', 'programming', 'tools', 'tech'],
          topic_terms: ['software', 'code', 'programming', 'tools', 'tech']
        },
        { 
          topic_id: "1", 
          topic_label: "Social Media", 
          keywords: ['twitter', 'posts', 'content', 'social', 'media'],
          topic_terms: ['twitter', 'posts', 'content', 'social', 'media']
        },
        { 
          topic_id: "2", 
          topic_label: "Art & Design", 
          keywords: ['art', 'design', 'creative', 'visual', 'color'],
          topic_terms: ['art', 'design', 'creative', 'visual', 'color']
        },
        { 
          topic_id: "3", 
          topic_label: "Productivity", 
          keywords: ['workflow', 'productivity', 'time', 'tasks', 'focus'],
          topic_terms: ['workflow', 'productivity', 'time', 'tasks', 'focus']
        },
        { 
          topic_id: "4", 
          topic_label: "Science & Research", 
          keywords: ['research', 'data', 'science', 'studies', 'analysis'],
          topic_terms: ['research', 'data', 'science', 'studies', 'analysis']
        }
      ];
      
      log(`Using ${defaultTopics.length} default topic categories`);
      setAvailableClusters(defaultTopics);
    }
  }, []);
  
  // Filter tweets by selected topic cluster - optimized for speed
  const filterTweetsByTopic = useCallback((topicId) => {
    // Hard limit to improve performance - never use more than this many tweets
    const TWEET_LIMIT = 50;
    
    if (!topicId && topicId !== 0) {
      // If no topic selected, just use a sample for better performance
      const sampleTweets = tweets.slice(0, TWEET_LIMIT);
      log(`Using ${sampleTweets.length} sample tweets`);
      setFilteredTweets(sampleTweets);
      return;
    }
    
    try {
      if (availableClusters) {
        // Find the cluster by ID (topics could be in any order in the array)
        const selectedTopic = availableClusters.find(cluster => cluster.topic_id === topicId);
        
        if (!selectedTopic) {
          throw new Error(`Topic ID ${topicId} not found`);
        }
        
        // Use topic_terms if available, otherwise fall back to keywords
        const keywords = selectedTopic.topic_terms || selectedTopic.keywords;
        
        // Pre-process keywords for faster matching
        const processedKeywords = keywords.map(k => k.toLowerCase());
        
        // Use every 10th tweet as initial candidate pool for faster performance
        // This dramatically reduces the number of tweets we need to examine
        const candidateTweets = [];
        const step = Math.max(1, Math.floor(tweets.length / 200));
        
        for (let i = 0; i < tweets.length; i += step) {
          candidateTweets.push(tweets[i]);
          if (candidateTweets.length >= TWEET_LIMIT * 2) break;
        }
        
        // Quick filter on the smaller candidate pool
        const matchedTweets = [];
        
        for (let i = 0; i < candidateTweets.length; i++) {
          const tweet = candidateTweets[i];
          const tweetText = (tweet.text || tweet.full_text || '').toLowerCase();
          
          for (let j = 0; j < processedKeywords.length; j++) {
            if (tweetText.includes(processedKeywords[j])) {
              matchedTweets.push(tweet);
              break; // Found a match for this tweet, move to next tweet
            }
          }
          
          // Stop if we have enough matches
          if (matchedTweets.length >= TWEET_LIMIT) break;
        }
        
        if (matchedTweets.length > 0) {
          log(`Found ${matchedTweets.length} tweets for topic "${selectedTopic.topic_label}"`);
          setFilteredTweets(matchedTweets);
        } else {
          // If no matches, just use a sample based on topic ID for consistency
          const sampleSize = Math.min(tweets.length, TWEET_LIMIT);
          const topicIdNum = parseInt(topicId, 10) || 0; // Convert string ID to number safely
          const startIndex = (topicIdNum * 17) % Math.max(1, tweets.length - sampleSize);
          const randomSample = tweets.slice(startIndex, startIndex + sampleSize);
          
          log(`No keyword matches found, using ${randomSample.length} sample tweets for topic "${selectedTopic.topic_label}"`);
          setFilteredTweets(randomSample);
        }
      } else {
        // Topic not found, use default sample
        const sampleTweets = tweets.slice(0, TWEET_LIMIT);
        log(`No topic information found, using ${sampleTweets.length} sample tweets`);
        setFilteredTweets(sampleTweets);
      }
    } catch (error) {
      console.error('Error in topic filtering:', error);
      
      // Fallback: use first few tweets
      const sampleTweets = tweets.slice(0, TWEET_LIMIT);
      log(`Using ${sampleTweets.length} sample tweets due to error`);
      setFilteredTweets(sampleTweets);
    }
  }, [tweets, availableClusters]);

  // Load topic data when component mounts
  useEffect(() => {
    fetchTopicData();
  }, [fetchTopicData]);
  
  // Add tweet-themed centerpiece to the scene
  const addTweetCenterpiece = (scene) => {
    log('Creating tweet centerpiece');
    
    // Create a marble pedestal for the artwork
    const pedestalGeometry = new THREE.CylinderGeometry(2, 2.5, 1.5, 32);
    const pedestalMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      roughness: 0.2,
      metalness: 0.1,
    });
    
    const pedestal = new THREE.Mesh(pedestalGeometry, pedestalMaterial);
    pedestal.position.set(0, 0.75, 0); // Positioned at center, half height off ground
    pedestal.castShadow = true;
    pedestal.receiveShadow = true;
    
    // Create decorative element around the pedestal
    const pedestalRing = new THREE.TorusGeometry(2.2, 0.1, 16, 32);
    const goldMaterial = new THREE.MeshStandardMaterial({
      color: 0xffd700,
      roughness: 0.1,
      metalness: 1.0,
    });
    
    const ring = new THREE.Mesh(pedestalRing, goldMaterial);
    ring.position.set(0, 0.05, 0);
    ring.rotation.x = Math.PI / 2;
    ring.castShadow = true;
    
    // Create the complex torus knot geometry
    const artworkGeometry = new THREE.TorusKnotGeometry(1, 0.3, 100, 16);
    const artworkMaterial = new THREE.MeshStandardMaterial({
      color: COLORS.accent,
      roughness: 0.2,
      metalness: 0.8,
      emissive: COLORS.accent,
      emissiveIntensity: 0.3
    });
    
    const artwork = new THREE.Mesh(artworkGeometry, artworkMaterial);
    artwork.position.set(0, 3.5, 0); // Positioned above the pedestal
    artwork.castShadow = true;
    artwork.receiveShadow = true;
    
    // Add point light inside the knot for glow effect
    const pointLight = new THREE.PointLight(COLORS.accent, 1.5, 10);
    pointLight.position.set(0, 0, 0);
    artwork.add(pointLight);
    
    // Create a group to hold both pedestal and artwork
    const centerpieceGroup = new THREE.Group();
    centerpieceGroup.add(pedestal);
    centerpieceGroup.add(ring);
    centerpieceGroup.add(artwork);
    
    // Store reference to the artwork for animation
    centralpieceRef.current = artwork;
    
    // Add to scene
    sceneRef.current.add(centerpieceGroup);
    
    log('Added centerpiece to museum');
    
    return centerpieceGroup;
  };
  
  // Function to update camera movement
  const updateCameraMovement = () => {
    const camera = cameraRef.current;
    if (!camera || !controlsRef.current) return;
  };
  
  useEffect(() => {
    if (!containerRef.current || clusterSelectionOpen) return;
    
    // Function to update camera position based on keys pressed
    const updateCameraMovement = () => {
      const camera = cameraRef.current;
      if (!camera || !controlsRef.current) return;
      
      // Get current keys pressed state and calculate effective movement speed
      const { keysPressed } = cameraStateRef.current;
      const baseSpeed = 0.2; // Slower base speed for smoother movement
      const sprintMultiplier = keysPressed.shift ? 3 : 1; // Shift key for sprint
      const effectiveSpeed = baseSpeed * sprintMultiplier;
      
      // Get camera direction
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);
      
      // Create a horizontal direction vector (ignore y component for flat movement)
      const horizontalDirection = new THREE.Vector3(direction.x, 0, direction.z).normalize();
      
      // Create right vector (perpendicular to direction)
      const rightVector = new THREE.Vector3()
        .crossVectors(new THREE.Vector3(0, 1, 0), horizontalDirection)
        .normalize();
      
      // Calculate movement vector based on keys pressed
      const movementVector = new THREE.Vector3(0, 0, 0);
      
      // Skip if no movement keys are pressed
      if (!keysPressed.w && !keysPressed.a && !keysPressed.s && !keysPressed.d && 
          !keysPressed[' '] && !keysPressed.c) return;
      
      if (keysPressed.w) movementVector.add(horizontalDirection);
      if (keysPressed.s) movementVector.sub(horizontalDirection);
      if (keysPressed.a) movementVector.add(rightVector);
      if (keysPressed.d) movementVector.sub(rightVector);
      
      // Space to move up, C to move down
      if (keysPressed[' ']) movementVector.y += 1;
      if (keysPressed.c) movementVector.y -= 1;
      
      // Normalize movement vector to maintain consistent speed when moving diagonally
      if (movementVector.length() > 0) {
        movementVector.normalize().multiplyScalar(effectiveSpeed);
        
        // Apply movement to camera position
        camera.position.add(movementVector);
        
        // Update controls target - keep looking in same direction
        controlsRef.current.target.set(
          camera.position.x + direction.x,
          camera.position.y + direction.y, 
          camera.position.z + direction.z
        );
      }
    };

    // Initialize Three.js scene
    const init = () => {
      log('Initializing 3D scene');
      
      // Create scene
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x87CEEB); // Set a clear sky color as fallback
      sceneRef.current = scene;

      // Create renderer
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
      renderer.shadowMap.enabled = true;
      
      // Create a beautiful sky background
      skyboxRef.current = createSkyBackground(scene);
      // Make sure we have a container to append to
      if (containerRef.current) {
        containerRef.current.appendChild(renderer.domElement);
        rendererRef.current = renderer;
      } else {
        console.error("Container reference is null when trying to append renderer");
      }

      // Create camera
      const aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
      const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
      // Set initial camera position from state ref
      const initialPosition = cameraStateRef.current.position;
      camera.position.set(
        initialPosition.x,
        initialPosition.y,
        initialPosition.z
      );
      cameraRef.current = camera;

      // Add controls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      // Set target to look forward at same height
      controls.target.set(
        camera.position.x + 1, // Slightly in front
        camera.position.y,     // Same height as camera
        camera.position.z - 10 // Looking into the scene
      );
      controlsRef.current = controls;

      // We'll skip adding the default lights here since we're using the sky system's lights
      // This ensures we have consistent lighting from the sky's sun position

      // Create museum architecture
      createMuseumLayout(scene);

      // Keep track of decorative elements for animation
      const decorativeElements = scene.children.filter(child => 
        child.isGroup && child.children.some(element => element.userData?.animation)
      );
      
      // Animation loop with smooth camera movement
      const animate = () => {
        animationFrameRef.current = requestAnimationFrame(animate);
        
        // Update camera position based on keys pressed
        updateCameraMovement();
        
        // Animate decorative elements
        const time = performance.now() * 0.001; // Convert to seconds
        
        // Animate the centerpiece if it exists
        if (centralpieceRef.current) {
          // Rotate the torus knot
          centralpieceRef.current.rotation.x += 0.01;
          centralpieceRef.current.rotation.y += 0.02;
          
          // Scale pulsation
          const scale = 1 + 0.05 * Math.sin(time * 2);
          centralpieceRef.current.scale.set(scale, scale, scale);
          
          // Color pulsation by changing emissive intensity
          if (centralpieceRef.current.material) {
            centralpieceRef.current.material.emissiveIntensity = 0.3 + 0.2 * Math.sin(time * 3);
          }
        }
        
        // Animate the sky if it exists (subtle rotation)
        if (skyboxRef.current) {
          // Very slow rotation for the sky
          skyboxRef.current.rotation.y = time * 0.01;
        }
        
        // Find and animate all decorative elements
        scene.traverse((object) => {
          if (object.userData?.animation) {
            // Apply rotation animation
            if (object.userData.animation.rotationSpeed) {
              object.rotation.x += object.userData.animation.rotationSpeed.x;
              object.rotation.y += object.userData.animation.rotationSpeed.y;
              object.rotation.z += object.userData.animation.rotationSpeed.z;
            }
            
            // Apply floating animation
            if (object.userData.animation.floatSpeed) {
              const floatOffset = Math.sin(time * object.userData.animation.floatSpeed + object.userData.animation.startTime) * object.userData.animation.floatHeight;
              object.position.y = object.userData.originalY + floatOffset;
            } else {
              // Store original Y position if not already stored
              if (object.userData.originalY === undefined) {
                object.userData.originalY = object.position.y;
              }
            }
          }
        });
        
        // Update controls
        controlsRef.current.update();
        
        // Render scene
        rendererRef.current.render(scene, camera);
      };
      
      animate();
      
      // Handle window resize
      const handleResize = () => {
        if (!containerRef.current) return;
        
        const width = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;
        
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      };
      
      window.addEventListener('resize', handleResize);
      
      // Add keyboard controls with smooth movement
      const handleKeyDown = (event) => {
        const key = event.key.toLowerCase();
        if (['w', 'a', 's', 'd', ' ', 'shift', 'c'].includes(key)) {
          setCameraState(prev => ({
            ...prev,
            keysPressed: {
              ...prev.keysPressed,
              [key]: true
            }
          }));
          event.preventDefault();
        }
      };
      
      const handleKeyUp = (event) => {
        const key = event.key.toLowerCase();
        if (['w', 'a', 's', 'd', ' ', 'shift', 'c'].includes(key)) {
          setCameraState(prev => ({
            ...prev,
            keysPressed: {
              ...prev.keysPressed,
              [key]: false
            }
          }));
          event.preventDefault();
        }
      };
      
      
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      
      setIsLoading(false);
      log('Scene initialized successfully');
      
      // Cleanup
      return () => {
        log('Cleaning up 3D scene');
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
        
        scene.traverse((object) => {
          if (object.geometry) {
            object.geometry.dispose();
          }
          
          if (object.material) {
            if (Array.isArray(object.material)) {
              object.material.forEach((material) => material.dispose());
            } else {
              object.material.dispose();
            }
          }
        });
        
        renderer.dispose();
        
        if (containerRef.current && renderer.domElement) {
          containerRef.current.removeChild(renderer.domElement);
        }
      };
    };

    // Call init immediately, but wrap in a timeout to improve perceived performance
    const initTimeout = setTimeout(init, 50);
    
    // Return cleanup function
    return () => {
      clearTimeout(initTimeout);
    };
  }, [filteredTweets]);

  // Create museum architecture using the imported utility function
  const createMuseumLayout = (scene) => {
    log('Creating museum architecture');
    
    // Limit tweet count for better performance
    const maxTweets = 40;
    const limitedTweets = filteredTweets.length > maxTweets ? 
      filteredTweets.slice(0, maxTweets) : 
      filteredTweets;
    
    // Add the centerpiece to the central dome - complex torus knot
    addTweetCenterpiece(scene);
    
    // Use selected cluster to filter tweets if available
    const analysisData = {
      selectedCluster: selectedCluster
    };
    
    // Create the museum with proper entrances and hallways (using limited tweet set)
    const { museum, layoutManager } = createMuseumArchitecture(scene, limitedTweets, analysisData);
    
    // Store the layout manager for potential later interaction
    scene.userData.layoutManager = layoutManager;
    
    log('Museum architecture created successfully');
  };

  // Handle fullscreen toggle
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
    // Need to trigger a resize event after state change to update renderer
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      log(fullscreen ? 'Exiting fullscreen view' : 'Entering fullscreen view');
    }, 100);
  };

  // Start the museum experience after selecting a cluster
  const startMuseum = (clusterId) => {
    setIsLoading(true);
    setSelectedCluster(clusterId);
    
    // Use setTimeout to allow the loading screen to appear before filtering
    setTimeout(() => {
      filterTweetsByTopic(clusterId);
      setClusterSelectionOpen(false);
      log(`Starting museum with cluster ${clusterId !== null ? clusterId : 'All'}`);
    }, 10);
  };

  return (
    <div className={`generative-museum ${fullscreen ? 'fullscreen' : ''}`}>
      {clusterSelectionOpen ? (
        <div className="cluster-selection">
          <h2>Select a Topic Cluster to Explore</h2>
          {availableClusters ? (
            <div className="cluster-grid">
              {availableClusters.map(cluster => (
                <div 
                  key={cluster.topic_id} 
                  className="cluster-item"
                  onClick={() => startMuseum(cluster.topic_id)}
                >
                  <h3>{cluster.topic_label || `Topic ${cluster.topic_id + 1}`}</h3>
                  <div className="keywords">
                    {cluster.keywords.map((keyword, idx) => (
                      <span key={idx} className="keyword">{keyword}</span>
                    ))}
                  </div>
                  <button>Explore This Topic</button>
                </div>
              ))}
              
              <div 
                className="cluster-item all-topics"
                onClick={() => startMuseum(null)}
              >
                <h3>All Topics</h3>
                <p>Explore the full collection</p>
                <button>Start Museum</button>
              </div>
            </div>
          ) : (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <div className="loading-text">Loading Topics...</div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="museum-container" ref={containerRef}>
            {isLoading && (
              <div className="loading-overlay">
                <div className="loading-spinner"></div>
                <div className="loading-text">Building Museum...</div>
              </div>
            )}
            
            <button 
              className="fullscreen-button" 
              onClick={toggleFullscreen}
              title={fullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
            >
              {fullscreen ? "Exit Fullscreen" : "Expand View"}
            </button>
            
            {/* <button 
              className="back-button" 
              onClick={() => setClusterSelectionOpen(true)}
              title="Return to Topic Selection"
            >
              ← Select Different Topic
            </button> */}
          </div>
          
          <div className="museum-controls">
            <div className="topic-info">
              <h3>Current Topic:</h3>
              {selectedCluster !== null ? (
                <p>
                  {availableClusters && availableClusters[selectedCluster]?.topic_label || `Topic ${selectedCluster + 1}`}: {availableClusters && 
                    availableClusters[selectedCluster]?.keywords.join(", ")}
                </p>
              ) : (
                <p>All Topics</p>
              )}
            </div>
            
            <div className="instructions">
              <h3>Controls:</h3>
              <p>Move: W, A, S, D</p>
              <p>Up/Down: Space / C</p>
              <p>Sprint: Hold Shift</p>
              <p>Look: Click and drag</p>
              <p>Zoom: Scroll wheel</p>
            </div>
            
            <div className="log-container">
              <h3>Museum Log:</h3>
              <div className="log-messages">
                {logMessages.slice(-5).map((log) => (
                  <div key={log.id} className="log-message">
                    {log.message}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GenerativeMuseum;