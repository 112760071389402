import * as THREE from 'three';
import { COLORS } from './MuseumUtils';

// Class to represent a room or dome in our museum
export class RoomNode {
  constructor(id, radius, height, position = { x: 0, y: 0, z: 0 }, type = 'regular') {
    this.id = id;
    this.radius = radius;
    this.height = height;
    this.position = position;
    this.type = type;
    this.connections = [];
    this.entrances = [];
    this.themeColor = null;
    this.dome = null; // Reference to the 3D dome object
  }

  // Add an entrance at the specified angle (in radians)
  addEntrance(angle, connectedRoomId) {
    const entranceWidth = 4.5; // Wider doorways
    const entranceHeight = 5; // Taller doorways
    
    // Calculate entrance position on the perimeter
    const entrancePosition = {
      x: this.position.x + this.radius * Math.cos(angle),
      y: this.position.y,
      z: this.position.z + this.radius * Math.sin(angle)
    };

    // Create entrance object
    const entrance = {
      id: `${this.id}_entrance_${this.entrances.length}`,
      angle,
      position: entrancePosition,
      width: entranceWidth,
      height: entranceHeight,
      connectedRoomId
    };

    this.entrances.push(entrance);
    return entrance;
  }

  // Add a connection to another room
  addConnection(roomId, entrance) {
    if (!this.connections.some(conn => conn.roomId === roomId)) {
      this.connections.push({
        roomId,
        entranceId: entrance.id
      });
    }
  }
}

// Class to represent a hallway between rooms
export class Hallway {
  constructor(id, startRoom, endRoom, startEntrance, endEntrance) {
    this.id = id;
    this.startRoomId = startRoom.id;
    this.endRoomId = endRoom.id;
    this.startEntrance = startEntrance;
    this.endEntrance = endEntrance;
    this.controlPoints = [];
  }

  // Generate a smooth path for the hallway
  generatePath(segments = 10) {
    // Create a straight path between entrances, forcing Y to ground level
    const points = [
      new THREE.Vector3(
        this.startEntrance.position.x, 
        0, // Ensure hallway starts at ground level
        this.startEntrance.position.z
      ),
      new THREE.Vector3(
        this.endEntrance.position.x, 
        0, // Ensure hallway ends at ground level
        this.endEntrance.position.z
      )
    ];
    
    // Debug info for path
    console.log(`Hallway ${this.id} path:`);
    console.log(`  Start: (${points[0].x.toFixed(2)}, ${points[0].y.toFixed(2)}, ${points[0].z.toFixed(2)})`);
    console.log(`  End: (${points[1].x.toFixed(2)}, ${points[1].y.toFixed(2)}, ${points[1].z.toFixed(2)})`);
    
    // Create a straight line
    const curve = new THREE.LineCurve3(points[0], points[1]);
    return curve.getPoints(segments);
  }
}

// Class to manage the overall museum layout
export class MuseumLayoutManager {
  constructor() {
    this.rooms = new Map();
    this.hallways = new Map();
  }

  // Add a room to the layout
  addRoom(room) {
    this.rooms.set(room.id, room);
  }

  // Connect two rooms with a hallway
  connectRooms(roomId1, roomId2) {
    const room1 = this.rooms.get(roomId1);
    const room2 = this.rooms.get(roomId2);
    
    if (!room1 || !room2) {
      console.error(`Cannot connect rooms: Room ${!room1 ? roomId1 : roomId2} not found`);
      return null;
    }
    
    // Calculate angle between rooms
    const angle1 = Math.atan2(
      room2.position.z - room1.position.z,
      room2.position.x - room1.position.x
    );
    
    const angle2 = Math.atan2(
      room1.position.z - room2.position.z,
      room1.position.x - room2.position.x
    );
    
    // Create entrances at the calculated angles
    const entrance1 = room1.addEntrance(angle1, roomId2);
    const entrance2 = room2.addEntrance(angle2, roomId1);
    
    // Add connections
    room1.addConnection(roomId2, entrance1);
    room2.addConnection(roomId1, entrance2);
    
    // Create hallway
    const hallwayId = `hallway_${roomId1}_${roomId2}`;
    const hallway = new Hallway(hallwayId, room1, room2, entrance1, entrance2);
    
    this.hallways.set(hallwayId, hallway);
    return hallway;
  }

  // Generate a radial layout (central hub with satellite domes)
  generateRadialLayout(
    centralRadius = 18, 
    centralHeight = 25, 
    satelliteRadius = 15, 
    satelliteHeight = 20, 
    satelliteCount = 3, 
    distanceFromCenter = 50 // Increased distance between buildings
  ) {
    // Clear any existing rooms and hallways to ensure clean generation
    this.rooms = new Map();
    this.hallways = new Map();
    
    console.log("== Generating Museum Layout ==");
    
    // Create central room
    const centralRoom = new RoomNode('central', centralRadius, centralHeight, { x: 0, y: 0, z: 0 }, 'central');
    centralRoom.themeColor = COLORS.walls; // Light color (Roman-inspired)
    this.addRoom(centralRoom);
    
    console.log("Created central room");
    
    // Create satellite rooms at fixed positions for predictable connections
    // Using fixed cardinal positions instead of angular distribution
    const satellitePositions = [
      { x: distanceFromCenter, y: 0, z: 0 },          // East position - exact right
      { x: 0, y: 0, z: distanceFromCenter },          // North position - exact forward
      { x: -distanceFromCenter, y: 0, z: 0 }          // West position - exact left
    ];
    
    const themeColors = [
      0xF2D1A8, // Warm sand (Roman) - East
      0xA8D4F2, // Light blue (Greek) - North
      0xF2B6A8  // Terracotta (Indian) - West
    ];
    
    // Create each satellite room at deterministic positions
    for (let i = 0; i < satelliteCount; i++) {
      const position = satellitePositions[i];
      const roomId = `satellite_${i}`;
      
      console.log(`Creating satellite room ${i} at position (${position.x}, ${position.y}, ${position.z})`);
      
      const room = new RoomNode(roomId, satelliteRadius, satelliteHeight, position, 'regular');
      
      // Assign theme color to the room
      room.themeColor = themeColors[i];
      
      this.addRoom(room);
      
      // Create connection to central room - with additional logging
      console.log(`Connecting central to ${roomId}`);
      this.connectRooms('central', roomId);
    }
    
    // We no longer connect satellites in a ring
    // This ensures hallways only go from outbuildings to central dome
  }
}