import React, { useState } from 'react';
import { useDevMode } from '../../context/DevModeContext';

const SkeletonImage = () => (
  <div className="skeleton-image animate-pulse bg-gray-200 dark:bg-gray-700"></div>
);

const ImageWithFallback = ({ src, localSrc, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [useLocal, setUseLocal] = useState(false);
  const { isDevMode } = useDevMode();

  const handleError = () => {
    if (!useLocal && localSrc) {
      console.log('Remote image failed, using local backup:', localSrc);
      setUseLocal(true);
    }
  };

  return (
    <div className={`relative ${className}`}>
      {!imageLoaded && <SkeletonImage />}
      <img
        src={useLocal ? localSrc : src}
        alt={alt}
        className={`w-full h-full object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        onLoad={() => setImageLoaded(true)}
        onError={handleError}
      />
      {isDevMode && useLocal && (
        <div className="absolute bottom-0 left-0 bg-purple-600 text-white text-xs px-2 py-1 rounded-tr">
          Using Local Backup
        </div>
      )}
    </div>
  );
};

export default ImageWithFallback;