import * as THREE from 'three';
import { RoomNode, Hallway, MuseumLayoutManager } from './topologyUtils';
import { createEntranceArch } from './entranceUtils';
import { createClassicalElements } from './RoomNode';

// Constants for museum configuration
export const COLORS = {
  background: 0x000000,
  walls: 0xffffff,
  wireframe: 0x000000,
  text: 0xffffff,
  accent: 0x1da1f2, // Twitter blue
  floor: 0xffffff, // Changed from gray to white
};

// Create a dome with walls and entrances
export const createDome = (roomNode) => {
  const { radius, height, position, entrances = [], themeColor } = roomNode;
  const { x, y, z } = position;
  const segments = 32; // Number of segments for the dome
  const group = new THREE.Group();
  group.position.set(x, y, z);
  
  // Create the dome/roof part
  const domeGeometry = new THREE.SphereGeometry(
    radius,
    segments,
    segments / 2,
    0,
    Math.PI * 2,
    0,
    Math.PI / 2
  );
  
  const domeMaterial = new THREE.MeshStandardMaterial({
    color: themeColor || COLORS.walls,
    side: THREE.DoubleSide,
    roughness: 0.5,
    metalness: 0.2,
  });
  
  const dome = new THREE.Mesh(domeGeometry, domeMaterial);
  dome.position.y = height / 2;
  dome.castShadow = true;
  dome.receiveShadow = true;
  group.add(dome);
  
  // Add wireframe to the dome
  const domeWireframe = new THREE.LineSegments(
    new THREE.EdgesGeometry(domeGeometry),
    new THREE.LineBasicMaterial({ color: COLORS.wireframe, linewidth: 2 })
  );
  domeWireframe.position.y = height / 2;
  group.add(domeWireframe);
  
  // Create a single complete cylindrical wall
  const wallGeometry = new THREE.CylinderGeometry(
    radius,
    radius,
    height * 0.5, // Lower height for walls
    segments,  // Full segments for smooth cylinder
    1,
    true
  );
  
  const wallMaterial = new THREE.MeshStandardMaterial({
    color: themeColor || COLORS.walls,
    side: THREE.DoubleSide,
    roughness: 0.5,
    metalness: 0.2,
  });
  
  const wall = new THREE.Mesh(wallGeometry, wallMaterial);
  wall.position.y = height * 0.25; // Position to align with the bottom of the dome
  wall.castShadow = true;
  wall.receiveShadow = true;
  // group.add(wall);
  
  // Add wireframe to the wall
  const wallWireframe = new THREE.LineSegments(
    new THREE.EdgesGeometry(wallGeometry),
    new THREE.LineBasicMaterial({ color: COLORS.wireframe, linewidth: 2 })
  );
  wallWireframe.position.y = height * 0.25;
  // group.add(wallWireframe);
  
  // For each entrance, create a entrance arch
  entrances.forEach(entrance => {
    createEntranceArch(group, entrance, radius, height);
  });
  
  return group;
};

// Create a hallway connecting two entrances - using BoxGeometry for simplicity and reliability
export const createHallway = (hallwayData, parent) => {
  const { startEntrance, endEntrance } = hallwayData;
  
  // Generate path for hallway
  const pathPoints = hallwayData.generatePath(2); 
  
  // Get start and end points
  const startPoint = pathPoints[0];
  const endPoint = pathPoints[pathPoints.length - 1];
  
  // Calculate hallway dimensions
  const hallwayWidth = Math.min(startEntrance.width, endEntrance.width) * 0.9;
  const hallwayHeight = Math.min(startEntrance.height, endEntrance.height) * 0.9;
  
  // Calculate direction and distance
  const direction = new THREE.Vector3().subVectors(
    new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z),
    new THREE.Vector3(startPoint.x, startPoint.y, startPoint.z)
  );
  
  const distance = direction.length();
  direction.normalize();
  
  // Create a group for the hallway
  const hallwayGroup = new THREE.Group();
  
  // SIMPLIFY: Use BoxGeometry for the hallway walls without end caps
  
  // Side walls material
  const wallMaterial = new THREE.MeshStandardMaterial({
    color: COLORS.walls,
    roughness: 0.5,
    metalness: 0.2
  });
  
  // Floor material
  const floorMaterial = new THREE.MeshStandardMaterial({
    color: COLORS.floor,
    roughness: 0.8,
    metalness: 0.2
  });
  
  // Create the hallway structure as a simple box WITHOUT end caps
  // Using segments to allow ends to be open
  const segments = 4; // Number of segments along hallway length
  
  // Create left and right walls (stretched boxes)
  const wallThickness = 0.2;
  const wallGeometry = new THREE.BoxGeometry(distance, hallwayHeight, wallThickness);
  
  // Left wall
  const leftWall = new THREE.Mesh(wallGeometry, wallMaterial);
  leftWall.position.set(0, hallwayHeight/2, hallwayWidth/2);
  leftWall.castShadow = true;
  leftWall.receiveShadow = true;
  hallwayGroup.add(leftWall);
  
  // Right wall
  const rightWall = new THREE.Mesh(wallGeometry, wallMaterial);
  rightWall.position.set(0, hallwayHeight/2, -hallwayWidth/2);
  rightWall.castShadow = true;
  rightWall.receiveShadow = true;
  hallwayGroup.add(rightWall);
  
  // Ceiling
  const ceilingGeometry = new THREE.BoxGeometry(distance, wallThickness, hallwayWidth);
  const ceiling = new THREE.Mesh(ceilingGeometry, wallMaterial);
  ceiling.position.set(0, hallwayHeight, 0);
  ceiling.castShadow = true;
  ceiling.receiveShadow = true;
  hallwayGroup.add(ceiling);
  
  // Floor
  const floorGeometry = new THREE.BoxGeometry(distance, wallThickness, hallwayWidth);
  const floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.position.set(0, 0, 0);
  floor.receiveShadow = true; // Floor typically only receives shadows
  hallwayGroup.add(floor);
  
  // Add wireframes to all elements
  [leftWall, rightWall, ceiling, floor].forEach(element => {
    const wireframe = new THREE.LineSegments(
      new THREE.EdgesGeometry(element.geometry),
      new THREE.LineBasicMaterial({ color: COLORS.wireframe, linewidth: 1 })
    );
    element.add(wireframe);
  });
  
  // Position the hallway at midpoint
  const midPoint = {
    x: (startPoint.x + endPoint.x) / 2,
    y: 0,
    z: (startPoint.z + endPoint.z) / 2
  };
  
  hallwayGroup.position.set(midPoint.x, midPoint.y, midPoint.z);
  
  // Rotate hallway to align with direction
  const angle = Math.atan2(direction.z, direction.x);
  hallwayGroup.rotation.y = angle;
  
  console.log(`Hallway from ${hallwayData.startRoomId} to ${hallwayData.endRoomId}:`);
  console.log(`  Direction angle: ${(angle * 180 / Math.PI).toFixed(2)} degrees`);
  console.log(`  Width: ${hallwayWidth.toFixed(2)}, Height: ${hallwayHeight.toFixed(2)}`);
  
  // Store hallway data and add to parent
  hallwayGroup.userData.hallway = hallwayData;
  parent.add(hallwayGroup);
  
  return hallwayGroup;
};

// Add tweet frames to a dome
export const addTweetFramesToDome = (dome, tweets, roomNode) => {
  const radius = roomNode.radius;
  if (!tweets || tweets.length === 0) return;
  
  const count = Math.min(tweets.length, 12);
  
  // Determine positions of entrances to avoid placing frames there
  const entranceAngles = roomNode.entrances.map(entrance => entrance.angle);
  
  // Calculate available angles for frames, avoiding entrances
  const availableAngles = [];
  const totalAngle = Math.PI * 2;
  const angleStep = totalAngle / count;
  
  for (let i = 0; i < count; i++) {
    let frameAngle = i * angleStep;
    
    // Check if this angle is too close to an entrance
    const tooCloseToEntrance = entranceAngles.some(entranceAngle => {
      const angleDiff = Math.abs((frameAngle - entranceAngle + Math.PI) % (Math.PI * 2) - Math.PI);
      return angleDiff < 0.3; // Minimum angle difference (in radians)
    });
    
    // If too close, adjust slightly
    if (tooCloseToEntrance) {
      frameAngle += 0.3; // Adjust by 0.3 radians (about 17 degrees)
    }
    
    availableAngles.push(frameAngle);
  }
  
  // Create frames at the available angles
  for (let i = 0; i < count; i++) {
    const tweet = tweets[i];
    const angle = availableAngles[i];
      
    // Position on the cylinder wall
    const x = radius * Math.cos(angle);
    const z = radius * Math.sin(angle);
    
    // Frame dimensions - larger frames for better visibility
    const frameWidth = 4.5; // Increased from 3
    const frameHeight = 3 + Math.sin(angle * 2) * 0.3; // Increased from 2
    
    // Create outer frame (Twitter blue frame)
    const outerFrameGeometry = new THREE.PlaneGeometry(frameWidth + 0.3, frameHeight + 0.3);
    const outerFrameMaterial = new THREE.MeshStandardMaterial({
      color: COLORS.accent, // Twitter blue color
      roughness: 0.5,
      metalness: 0.3,
      side: THREE.DoubleSide,
    });
    
    const outerFrame = new THREE.Mesh(outerFrameGeometry, outerFrameMaterial);
    
    // Create inner frame (canvas background)
    const frameGeometry = new THREE.PlaneGeometry(frameWidth, frameHeight);
    const frameMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff, // Pure white
      side: THREE.DoubleSide,
    });
    
    const frame = new THREE.Mesh(frameGeometry, frameMaterial);
    frame.position.z = 0.01; // Slightly in front of outer frame
    outerFrame.add(frame);
    
    const normal = new THREE.Vector3(x, 0, z).normalize();
    const offset = -1.4; // Increased offset to prevent wall clipping
    
    // Position frame on the wall with slight offset to prevent clipping
    // Vary y position slightly based on angle for visual interest
    const yPos = 4 + Math.sin(angle * 3) * 0.5; // Vary height between 3.5 and 4.5
    
    outerFrame.position.set(
      x - normal.x * offset,
      yPos,
      z - normal.z * offset
    );
    
    // Calculate tangent vector along wall (perpendicular to normal vector)
    const tangent = new THREE.Vector3(-normal.z, 0, normal.x).normalize();
    
    // Create rotation matrix using normal and tangent
    const m = new THREE.Matrix4().makeBasis(
      tangent,
      new THREE.Vector3(0, 1, 0),
      normal.clone().multiplyScalar(-1) // Flip normal to face inward
    );
    
    // Apply correct orientation from rotation matrix
    outerFrame.setRotationFromMatrix(m);
    
    // Add frame borders - inner border (around content)
    const borderGeometry = new THREE.EdgesGeometry(frameGeometry);
    const borderMaterial = new THREE.LineBasicMaterial({
      color: COLORS.accent,
      linewidth: 3, // Thicker border
    });
    
    const border = new THREE.LineSegments(borderGeometry, borderMaterial);
    frame.add(border);
    
    // Add outer border (around frame)
    const outerBorderGeometry = new THREE.EdgesGeometry(outerFrameGeometry);
    const outerBorderMaterial = new THREE.LineBasicMaterial({
      color: 0x0a5080, // Darker blue for contrast with the Twitter blue
      linewidth: 3, // Thicker border
    });
    
    const outerBorder = new THREE.LineSegments(outerBorderGeometry, outerBorderMaterial);
    outerFrame.add(outerBorder);
    
    // Add tweet text
    const textMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      side: THREE.DoubleSide,
    });
    
    // Create canvas for tweet text
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 1024; // Higher resolution for better text rendering
    canvas.height = 512;
    
    // Fill with white background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    
    // Draw tweet text
    context.fillStyle = '#000000';
    
    // Get username
    const username = tweet.user?.screen_name || tweet.author || 'Unknown';
    
    // Draw username with even larger, bolder font
    context.font = 'bold 60px Arial'; // Increased from 52px
    context.fillText(`@${username}`, 40, 80); // Adjusted position
    
    // Add a subtle line under the username
    context.strokeStyle = '#1da1f2'; // Twitter blue
    context.lineWidth = 4;
    context.beginPath();
    context.moveTo(40, 95);
    context.lineTo(canvas.width - 40, 95);
    context.stroke();
    
    // Draw tweet text - word wrap with much larger font
    context.font = '48px Arial'; // Increased from 42px
    
    const text = tweet.text || 'No text available';
    const words = text.split(' ');
    let line = '';
    let y = 160; // Start text lower to accommodate larger username
    
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      
      if (testWidth > canvas.width - 100 && i > 0) { // Even wider margins
        context.fillText(line, 50, y); // Increased left margin
        line = words[i] + ' ';
        y += 55; // Larger line height for readability with bigger font
        
        // Maximum 5 lines due to larger font
        if (y > 400) { // Adjusted for larger canvas
          context.fillText(line + '...', 50, y);
          break;
        }
      } else {
        line = testLine;
      }
    }
    
    if (y <= 400) {
      context.fillText(line, 50, y); // Consistent with the margin above
    }
    
    // Create texture from canvas
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    
    // Apply texture to frame
    frame.material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
    });
    
    // Add frame to dome
    dome.add(outerFrame);
  }
};

// Add placeholder frames to a dome when no tweets are available
export const addPlaceholderFramesToDome = (dome, count, roomNode) => {
  const radius = roomNode.radius;
  // Determine position of entrances to avoid placing frames there
  const entranceAngles = roomNode.entrances.map(entrance => entrance.angle);
  
  // Calculate available angles for frames, avoiding entrances
  const availableAngles = [];
  const totalAngle = Math.PI * 2;
  const angleStep = totalAngle / count;
  
  for (let i = 0; i < count; i++) {
    let frameAngle = i * angleStep;
    
    // Check if this angle is too close to an entrance
    const tooCloseToEntrance = entranceAngles.some(entranceAngle => {
      const angleDiff = Math.abs((frameAngle - entranceAngle + Math.PI) % (Math.PI * 2) - Math.PI);
      return angleDiff < 0.3; // Minimum angle difference (in radians)
    });
    
    // If too close, try to adjust slightly
    if (tooCloseToEntrance) {
      frameAngle += 0.3; // Adjust by 0.3 radians (about 17 degrees)
    }
    
    availableAngles.push(frameAngle);
  }
  
  // Create frames at the available angles
  for (let i = 0; i < count; i++) {
    const angle = availableAngles[i];
      
    // Position on the cylinder wall
    const x = radius * Math.cos(angle);
    const z = radius * Math.sin(angle);
    
    // Frame dimensions - larger frames for better visibility
    const frameWidth = 4.5; // Increased from 3
    const frameHeight = 3 + Math.sin(angle * 2) * 0.3; // Increased from 2
    
    // Create outer frame (Twitter blue frame)
    const outerFrameGeometry = new THREE.PlaneGeometry(frameWidth + 0.3, frameHeight + 0.3);
    const outerFrameMaterial = new THREE.MeshStandardMaterial({
      color: COLORS.accent, // Twitter blue color
      roughness: 0.5,
      metalness: 0.3,
      side: THREE.DoubleSide,
    });
    
    const outerFrame = new THREE.Mesh(outerFrameGeometry, outerFrameMaterial);
    
    // Create inner frame (canvas background)
    const frameGeometry = new THREE.PlaneGeometry(frameWidth, frameHeight);
    const frameMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff, // Pure white
      side: THREE.DoubleSide,
    });
    
    const frame = new THREE.Mesh(frameGeometry, frameMaterial);
    frame.position.z = 0.01; // Slightly in front of outer frame
    outerFrame.add(frame);
    
    const normal = new THREE.Vector3(x, 0, z).normalize();
    const offset = 0.4; // Increased offset to prevent wall clipping
    
    // Position frame on the wall with slight offset to prevent clipping
    // Vary y position slightly based on angle for visual interest
    const yPos = 4 + Math.sin(angle * 3) * 0.5; // Vary height between 3.5 and 4.5
    
    outerFrame.position.set(
      x - normal.x * offset,
      yPos,
      z - normal.z * offset
    );
    
    // Calculate tangent vector along wall (perpendicular to normal vector)
    const tangent = new THREE.Vector3(-normal.z, 0, normal.x).normalize();
    
    // Create rotation matrix using normal and tangent
    const m = new THREE.Matrix4().makeBasis(
      tangent,
      new THREE.Vector3(0, 1, 0),
      normal.clone().multiplyScalar(-1) // Flip normal to face inward
    );
    
    // Apply correct orientation from rotation matrix
    outerFrame.setRotationFromMatrix(m);
    
    // Add frame borders - inner border (around content)
    const borderGeometry = new THREE.EdgesGeometry(frameGeometry);
    const borderMaterial = new THREE.LineBasicMaterial({
      color: COLORS.accent,
      linewidth: 3, // Thicker border
    });
    
    const border = new THREE.LineSegments(borderGeometry, borderMaterial);
    frame.add(border);
    
    // Add outer border (around frame)
    const outerBorderGeometry = new THREE.EdgesGeometry(outerFrameGeometry);
    const outerBorderMaterial = new THREE.LineBasicMaterial({
      color: 0x0a5080, // Darker blue for contrast with the Twitter blue
      linewidth: 3, // Thicker border
    });
    
    const outerBorder = new THREE.LineSegments(outerBorderGeometry, outerBorderMaterial);
    outerFrame.add(outerBorder);
    
    // Create canvas for placeholder text
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 1024; // Higher resolution for better text rendering
    canvas.height = 512;
    
    // Fill with white background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    
    // Add subtle border inside the canvas
    context.strokeStyle = '#1da1f2'; // Twitter blue
    context.lineWidth = 5;
    context.strokeRect(20, 20, canvas.width - 40, canvas.height - 40);
    
    // Draw placeholder text
    context.fillStyle = '#000000';
    context.font = 'bold 72px Arial'; // Even larger font
    context.textAlign = 'center';
    context.fillText('Tweet Display', canvas.width / 2, 180);
    
    // Add decorative underline
    context.strokeStyle = '#1da1f2';
    context.lineWidth = 4;
    context.beginPath();
    context.moveTo(canvas.width/2 - 250, 200);
    context.lineTo(canvas.width/2 + 250, 200);
    context.stroke();
    
    context.font = '56px Arial'; // Larger font
    context.fillText('Frame ' + (i + 1), canvas.width / 2, 300);
    
    // Create texture from canvas
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    
    // Apply texture to frame
    frame.material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
    });
    
    // Add frame to dome
    dome.add(outerFrame);
  }
};

// Add decorative floating elements to the scene
const addDecorativeElements = (scene) => {
  // Create a group for decorative elements
  const decorations = new THREE.Group();
  
  // Create several random geometric elements
  const shapes = [
    // Create a floating sphere
    () => {
      const geometry = new THREE.SphereGeometry(1, 16, 16);
      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff, // Always pure white for decorative elements
        transparent: true,
        opacity: 0.7,
        wireframe: false,
      });
      const mesh = new THREE.Mesh(geometry, material);
      
      // Add wireframe
      const wireframe = new THREE.LineSegments(
        new THREE.EdgesGeometry(geometry),
        new THREE.LineBasicMaterial({ color: COLORS.wireframe })
      );
      mesh.add(wireframe);
      
      return mesh;
    },
    
    // Create a floating cube
    () => {
      const geometry = new THREE.BoxGeometry(1.5, 1.5, 1.5);
      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff, // Always pure white for decorative elements
        transparent: true,
        opacity: 0.7,
        wireframe: false,
      });
      const mesh = new THREE.Mesh(geometry, material);
      
      // Add wireframe
      const wireframe = new THREE.LineSegments(
        new THREE.EdgesGeometry(geometry),
        new THREE.LineBasicMaterial({ color: COLORS.wireframe })
      );
      mesh.add(wireframe);
      
      return mesh;
    },
    
    // Create a floating torus
    () => {
      const geometry = new THREE.TorusGeometry(1, 0.4, 16, 32);
      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff, // Always pure white for decorative elements
        transparent: true,
        opacity: 0.7,
        wireframe: false,
      });
      const mesh = new THREE.Mesh(geometry, material);
      
      // Add wireframe
      const wireframe = new THREE.LineSegments(
        new THREE.EdgesGeometry(geometry),
        new THREE.LineBasicMaterial({ color: COLORS.wireframe })
      );
      mesh.add(wireframe);
      
      return mesh;
    },
    
    // Create a floating octahedron
    () => {
      const geometry = new THREE.OctahedronGeometry(1);
      const material = new THREE.MeshStandardMaterial({
        color: 0xffffff, // Always pure white for decorative elements
        transparent: true,
        opacity: 0.7,
        wireframe: false,
      });
      const mesh = new THREE.Mesh(geometry, material);
      
      // Add wireframe
      const wireframe = new THREE.LineSegments(
        new THREE.EdgesGeometry(geometry),
        new THREE.LineBasicMaterial({ color: COLORS.wireframe })
      );
      mesh.add(wireframe);
      
      return mesh;
    },
  ];
  
  // Generate about 12 random elements
  for (let i = 0; i < 12; i++) {
    // Choose a random shape function
    const shapeIndex = Math.floor(Math.random() * shapes.length);
    const element = shapes[shapeIndex]();
    
    // Position randomly within a reasonable area
    const radius = 15 + Math.random() * 20;
    const angle = Math.random() * Math.PI * 2;
    const height = 5 + Math.random() * 15;
    
    element.position.set(
      radius * Math.cos(angle),
      height,
      radius * Math.sin(angle)
    );
    
    // Store original Y position for animation
    element.userData.originalY = height;
    
    // Random rotation
    element.rotation.set(
      Math.random() * Math.PI * 2,
      Math.random() * Math.PI * 2,
      Math.random() * Math.PI * 2
    );
    
    // Random scale (but keep aspect ratio)
    const scale = 0.5 + Math.random() * 1.5;
    element.scale.set(scale, scale, scale);
    
    // Add to decorations group
    decorations.add(element);
    
    // Add animation data as a property to use in animation loop
    element.userData.animation = {
      rotationSpeed: {
        x: (Math.random() - 0.5) * 0.005,
        y: (Math.random() - 0.5) * 0.005,
        z: (Math.random() - 0.5) * 0.005,
      },
      floatSpeed: 0.2 + Math.random() * 0.8,
      floatHeight: 0.5 + Math.random() * 1.0,
      startTime: Math.random() * Math.PI * 2,
    };
  }
  
  // Add the decorations group to the scene
  scene.add(decorations);
  
  // Return the decorations group for later animation
  return decorations;
};

// Create an architectural museum layout using the layout manager

export const createMuseumArchitecture = (scene, tweets, clusterData = null) => {
  // Create a group for the entire museum
  const museum = new THREE.Group();
  
  // Create a layout manager
  const layoutManager = new MuseumLayoutManager();
  
  // Generate radial layout with central and satellite domes
  // Let the layout manager use its default values which are now larger
  layoutManager.generateRadialLayout();
  
  // Create domes for each room in the layout
  layoutManager.rooms.forEach(roomNode => {
    const dome = createDome(roomNode);
    museum.add(dome);
    
    // Store reference to the dome in the room node for later use
    roomNode.dome = dome;
    
    // Add classical architectural elements based on room type
    createClassicalElements(museum, roomNode);
  });
  
  // Create hallways between connected rooms - with debug logging
  console.log(`Creating ${layoutManager.hallways.size} hallways`);
  
  // Convert to array and ensure we process hallways in a consistent order
  const hallwaysArray = Array.from(layoutManager.hallways.values());
  
  // Sort hallways by id for consistent processing order
  hallwaysArray.sort((a, b) => a.id.localeCompare(b.id));
  
  // Create each hallway
  hallwaysArray.forEach((hallway, index) => {
    console.log(`Creating hallway ${index + 1}/${hallwaysArray.length}: ${hallway.id}`);
    console.log(`  From: ${hallway.startRoomId} to ${hallway.endRoomId}`);
    createHallway(hallway, museum);
  });
  
  // Distribute tweets among the domes if available
  if (tweets && tweets.length > 0) {
    // Group tweets by the number of rooms
    const roomCount = layoutManager.rooms.size;
    const tweetsPerRoom = Math.ceil(tweets.length / roomCount);
    
    let tweetIndex = 0;
    layoutManager.rooms.forEach((roomNode) => {
      const roomTweets = tweets.slice(tweetIndex, tweetIndex + tweetsPerRoom);
      tweetIndex += tweetsPerRoom;
      
      if (roomTweets.length > 0) {
        // Pass the room node to provide access to entrances for frame placement
        addTweetFramesToDome(roomNode.dome, roomTweets, roomNode);
      } else {
        // Add placeholder frames if no tweets available for this room
        addPlaceholderFramesToDome(roomNode.dome, 8, roomNode);
      }
    });
  } else {
    // Add placeholder frames to all domes if no tweets
    layoutManager.rooms.forEach((roomNode) => {
      addPlaceholderFramesToDome(roomNode.dome, 8, roomNode);
    });
  }
  
  // Add museum to the scene
  scene.add(museum);
  
  // Add floor - much larger to accommodate larger buildings
  const floorGeometry = new THREE.CircleGeometry(100, 64);
  const floorMaterial = new THREE.MeshStandardMaterial({
    color: COLORS.walls,
    roughness: 0.8,
    metalness: 0.2,
  });
  
  const floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.rotation.x = -Math.PI / 2;
  floor.position.y = -0.1;
  floor.receiveShadow = true;
  scene.add(floor);
  
  // Add wireframe to the floor
  const floorWireframe = new THREE.LineSegments(
    new THREE.EdgesGeometry(floorGeometry),
    new THREE.LineBasicMaterial({ color: COLORS.wireframe, linewidth: 1 })
  );
  floorWireframe.rotation.x = -Math.PI / 2;
  floorWireframe.position.y = -0.05;
  scene.add(floorWireframe);
  
  // Add floating decorative elements
  addDecorativeElements(scene);
  
  // Return the museum group and layout manager for potential later use
  return { museum, layoutManager };
};