import React from 'react';
import ImageWithFallback from './ImageWithFallback';
import { MessageCircle, Share2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import RedditLogo from './RedditLogo';
import UpvoteIcon from './UpvoteIcon';
import DownvoteIcon from './DownvoteIcon';
import PostIcon from './PostIcon';
import NSFWIcon from './NSFWIcon';
import './RedditCard.css';
import SearchLink from './SearchLink';

const RedditCard = ({ post, isInsightsView = false }) => {
  if (isInsightsView) {
    return (
      <div className="reddit-card reddit-insights-view">
        <div className="reddit-card-content">
          <div className="flex items-start">
            <div className="reddit-vote-section">
              <div className="reddit-upvote-button reddit-vote-button">
                <UpvoteIcon size={24} className="reddit-upvote-icon" />
              </div>
              <span className="reddit-vote-count">{post.ups}</span>
              <div className="reddit-downvote-button reddit-vote-button">
                <DownvoteIcon size={24} className="reddit-downvote-icon" />
              </div>
            </div>
            <div className="reddit-post-content">
              <div className="reddit-icon-container">
                <PostIcon size={40} />
              </div>
              {/* <ImageWithFallback
                src={post.thumbnail}
                localSrc={post.localThumbnail}
                alt="Post thumbnail"
                className="reddit-post-thumbnail"
              /> */}

              <div>

                <h3 className="reddit-post-title">
                  <a href={post.url} target="_blank" rel="noopener noreferrer" className="reddit-link">
                    {post.title}
                  </a>
                </h3>
                <p className="reddit-post-meta">
                  Posted by
                  <a href={`https://www.reddit.com/user/${post.author}/`} target="_blank" rel="noopener noreferrer" className="reddit-link">
                    u/{post.author}
                  </a>
                  {' in '}
                  <a href={`https://www.reddit.com/r/${post.subreddit}/`} target="_blank" rel="noopener noreferrer" className="reddit-link">
                    r/{post.subreddit}
                  </a>
                </p>
              </div>
              <div className="reddit-post-footer">
                <a href={post.url} target="_blank" rel="noopener noreferrer" className="reddit-footer-action">
                  <MessageCircle size={18} className="mr-2" />
                  <span>{post.num_comments} comments</span>
                </a>
                <button className="reddit-footer-action">
                  <Share2 size={18} className="mr-2" />
                  <span>Share</span>
                </button>
                <a
                  href={post.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="reddit-footer-action"
                  aria-label="Visit Reddit"
                >
                  <RedditLogo size={16} />
                  <span className="sr-only">Reddit</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isComment = post.hasOwnProperty('_replies');

  const showPostIcon = !isComment && (!post.thumbnail || post.thumbnail === 'spoiler' || post.thumbnail === 'self' || post.thumbnail === 'default' || post.thumbnail === 'nsfw');
  const showNSFWIcon = !isComment && (post.thumbnail === 'nsfw');
  const showThumbnail = !isComment && post.thumbnail && !showPostIcon && !showNSFWIcon;

  return (
    <div className={`reddit-card ${isComment ? 'reddit-comment' : ''}`}>
      <div className="reddit-card-content">
        <div className="flex items-start">
          <div className="reddit-vote-section">
            <div className="reddit-upvote-button reddit-vote-button">
              <UpvoteIcon size={24} className="reddit-upvote-icon" />
            </div>
            <span className="reddit-vote-count">{post.ups}</span>
            <div className="reddit-downvote-button reddit-vote-button">
              <DownvoteIcon size={24} className="reddit-downvote-icon" />
            </div>
          </div>
          <div className="reddit-post-content">
            {showPostIcon && (
              <div className="reddit-icon-container">
                <PostIcon size={40} />
              </div>
            )}
            {showNSFWIcon && (
              <div className="reddit-icon-container">
                <NSFWIcon size={40} />
              </div>
            )}
            {showThumbnail && (
              <img src={post.thumbnail} alt="Post thumbnail" className="reddit-post-thumbnail" />
            )}
            <div>
              {!isComment && (
                <h3 className="reddit-post-title">
                  <a href={post.url} target="_blank" rel="noopener noreferrer" className="reddit-link">
                    {post.title}
                  </a>
                </h3>
              )}
              {isComment && post.title && (
                <a href={post.url} target="_blank" rel="noopener noreferrer" className="reddit-link">
                  {post.title}
                </a>
              )}
              <p className="reddit-post-meta">
                {isComment ? 'Comment by ' : 'Posted by '}
                <a href={`https://www.reddit.com/user/${post.author}/`} target="_blank" rel="noopener noreferrer" className="tweet-handle">
                  u/{post.author}</a>
                {/* <SearchLink
                  operator="from"
                  value={post.author}
                  className="reddit-link"
                  showParens={false}
                >
                  u/{post.author}
                </SearchLink> */}
                {' in '}
                <SearchLink
                  operator="subreddit"
                  value={post.subreddit}
                  className="tweet-handle"
                  showParens={false}
                >
                  r/{post.subreddit}
                </SearchLink>
                {/* <a href={`https://www.reddit.com/r/${post.subreddit}/`} target="_blank" rel="noopener noreferrer" className="reddit-link"></a> */}
              </p>
            </div>
            <div className="reddit-post-text">
              <ReactMarkdown
                components={{
                  a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" className="reddit-link" aria-label="Reddit" />,
                  p: ({ node, ...props }) => <p {...props} className="mb-2" />,
                }}
              >
                {post.text || post.body}
              </ReactMarkdown>
            </div>
            <div className="reddit-post-footer">
              <a href={post.url} target="_blank" rel="noopener noreferrer" className="reddit-footer-action">
                <MessageCircle size={18} className="mr-2" />
                <span>{post.num_comments} comments</span>
              </a>
              <button className="reddit-footer-action">
                <Share2 size={18} className="mr-2" />
                <span>Share</span>
              </button>
              <a
                href={post.url}
                target="_blank"
                rel="noopener noreferrer"
                className="reddit-footer-action"
                aria-label="Visit Reddit"
              >
                <RedditLogo size={16} />
                <span className="sr-only">Reddit</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedditCard;