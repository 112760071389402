import * as THREE from 'three';
import { COLORS } from './MuseumUtils';

// Create an entrance arch
export const createEntranceArch = (parent, entrance, radius, height) => {
  const { angle, width, height: entranceHeight } = entrance;
  
  // Create arch geometry - make sure height is not too tall (max 40% of room height)
  const archWidth = width;
  const archHeight = Math.min(entranceHeight, height * 0.4);
  const archDepth = 1.0; // Make deeper for better visibility
  
  // Calculate position
  const x = radius * Math.cos(angle);
  const z = radius * Math.sin(angle);
  
  // Create entrance group
  const entranceGroup = new THREE.Group();
  
  // Create the entrance arch frame - using separate planes for sides and top
  const pillarWidth = archWidth * 0.15;
  const pillarDepth = archDepth;
  
  // Left pillar
  const leftPillarGeometry = new THREE.BoxGeometry(pillarWidth, archHeight, pillarDepth);
  const pillarMaterial = new THREE.MeshStandardMaterial({
    color: COLORS.accent, // Use accent color
    roughness: 0.5,
    metalness: 0.3,
  });
  
  const leftPillar = new THREE.Mesh(leftPillarGeometry, pillarMaterial);
  leftPillar.position.set(-archWidth/2 + pillarWidth/2, archHeight/2, 0);
  
  // Right pillar
  const rightPillar = new THREE.Mesh(leftPillarGeometry, pillarMaterial);
  rightPillar.position.set(archWidth/2 - pillarWidth/2, archHeight/2, 0);
  
  // Top lintel
  const lintelGeometry = new THREE.BoxGeometry(archWidth, pillarWidth, pillarDepth);
  const lintel = new THREE.Mesh(lintelGeometry, pillarMaterial);
  lintel.position.set(0, archHeight - pillarWidth/2, 0);
  
  // Add pillars and lintel to entrance group
  entranceGroup.add(leftPillar);
  entranceGroup.add(rightPillar);
  entranceGroup.add(lintel);
  
  // Add wireframes
  const addWireframe = (mesh) => {
    const wireframe = new THREE.LineSegments(
      new THREE.EdgesGeometry(mesh.geometry),
      new THREE.LineBasicMaterial({ color: COLORS.wireframe, linewidth: 2 })
    );
    mesh.add(wireframe);
  };
  
  addWireframe(leftPillar);
  addWireframe(rightPillar);
  addWireframe(lintel);
  
  // Position the entrance group
  entranceGroup.position.set(x * 0.98, 0, z * 0.98); // Position with base at floor level
  
  // Rotate frame to face outward from the center
  entranceGroup.lookAt(new THREE.Vector3(x * 2, 0, z * 2));
  
  // Add to parent
  parent.add(entranceGroup);
  
  // Store the entrance reference
  entranceGroup.userData.entrance = entrance;
  
  return entranceGroup;
};