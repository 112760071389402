import * as THREE from 'three';
import { COLORS } from './MuseumUtils';
// Classical architecture utility functions
export const createClassicalElements = (scene, roomNode) => {
  const { radius, height, position, type } = roomNode;
  const { x, y, z } = position;
  
  const group = new THREE.Group();
  group.position.set(x, y, z);
  
  // If this is a central room, add columns in a circular pattern
  // if (type === 'central' && radius > 15) {
    // Create Roman columns around the perimeter
    const numColumns = 16;
    const columnRadius = radius ;
    const columnHeight = height * 0.5;
    
    for (let i = 0; i < numColumns; i++) {
      // Skip positions where entrances are located
      const angle = (i / numColumns) * Math.PI * 2;
      
      // Check if column would interfere with an entrance
      const isNearEntrance = roomNode.entrances.some(entrance => {
        const angleDiff = Math.abs((angle - entrance.angle + Math.PI) % (Math.PI * 2) - Math.PI);
        return angleDiff < 0.3; // Skip if too close to entrance
      });
      
      if (!isNearEntrance) {
        const columnX = columnRadius * Math.cos(angle);
        const columnZ = columnRadius * Math.sin(angle);
        
        // Create a Roman column with base, shaft, and capital
        const column = createRomanColumn(columnHeight, height * 0.04, roomNode);
        column.position.set(columnX, 0, columnZ);
        
        // Have column face outward
        column.lookAt(new THREE.Vector3(columnX * 2, 0, columnZ * 2));
        
        group.add(column);
      }
    }
  // }
  
  // If this is a satellite building, add architectural elements based on position
  if (type === 'regular') {
    // Architectural features will vary based on position (determines culture)
    // We'll use the x coordinate to determine style
    if (position.x > 0) {
      // Greek-style pediments and friezes
      addGreekElements(group, radius, height);
    } else if (position.z > 0) {
      // Roman-style arches and columns
      addRomanElements(group, radius, height);
    } else {
      // Indian-style decorative elements
      addIndianElements(group, radius, height);
    }
  }
  
  // Add the architectural group to the scene
  scene.add(group);
  
  return group;
};

// Create a classical Roman column with base, shaft, and capital
const createRomanColumn = (height, radius, roomNode) => {
  const group = new THREE.Group();
  
  // Constants for proportions
  const baseHeight = height * 0.1;
  const capitalHeight = height * 0.15;
  const shaftHeight = height - baseHeight - capitalHeight;
  
  // Create the base (wider than the shaft)
  const baseGeometry = new THREE.CylinderGeometry(radius * 1.3, radius * 1.5, baseHeight, 16);
  const baseMaterial = new THREE.MeshStandardMaterial({
    color: roomNode.themeColor || COLORS.walls,
    roughness: 0.7,
    metalness: 0.1
  });
  
  const base = new THREE.Mesh(baseGeometry, baseMaterial);
  base.position.y = baseHeight / 2;
  base.castShadow = true;
  base.receiveShadow = true;
  
  // Create the shaft with slight taper
  const shaftGeometry = new THREE.CylinderGeometry(radius * 0.9, radius, shaftHeight, 16);
  const shaftMaterial = new THREE.MeshStandardMaterial({
    color: roomNode.themeColor || COLORS.walls,
    roughness: 0.5,
    metalness: 0.1
  });
  
  const shaft = new THREE.Mesh(shaftGeometry, shaftMaterial);
  shaft.position.y = baseHeight + shaftHeight / 2;
  shaft.castShadow = true;
  shaft.receiveShadow = true;
  
  // Create the capital (Corinthian - simplified)
  const capitalGeometry = new THREE.CylinderGeometry(radius * 1.5, radius * 0.9, capitalHeight, 16);
  const capitalMaterial = new THREE.MeshStandardMaterial({
    color: roomNode.themeColor || COLORS.walls,
    roughness: 0.7,
    metalness: 0.1
  });
  
  const capital = new THREE.Mesh(capitalGeometry, capitalMaterial);
  capital.position.y = baseHeight + shaftHeight + capitalHeight / 2;
  capital.castShadow = true;
  capital.receiveShadow = true;
  
  // Add all parts to the group
  group.add(base);
  group.add(shaft);
  group.add(capital);
  
  // Add wireframe to each component
  const addWireframe = (mesh) => {
    const wireframe = new THREE.LineSegments(
      new THREE.EdgesGeometry(mesh.geometry),
      new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 1 })
    );
    mesh.add(wireframe);
  };
  
  addWireframe(base);
  addWireframe(shaft);
  addWireframe(capital);
  
  return group;
};

// Add Greek architectural elements
const addGreekElements = (group, radius, height) => {
  // We'll skip implementing this for brevity but would add:
  // - Pediments
  // - Friezes
  // - Doric columns
};

// Add Roman architectural elements
const addRomanElements = (group, radius, height) => {
  // We'll skip implementing this for brevity but would add:
  // - Arches
  // - Vaults
  // - Corinthian columns
};

// Add Indian architectural elements
const addIndianElements = (group, radius, height) => {
  // We'll skip implementing this for brevity but would add:
  // - Ornate carvings
  // - Stepped structures
  // - Domes and spires
};